<template>
  <div></div>
</template>

<script>
export default {
  name: "DotPad320Key-0.1",
  emits: ['twentyCellsLeftPanning', 'twentyCellsRightPanning'],
  methods: {
    onSingleConnection(dotPad, key) {
      switch (key.name) {
        // 20셀 왼쪽 패닝
        case "Left Pan":
          this.twentyCellsLeftPanning(dotPad);
          break;
        // 20셀 오른쪽 패닝
        case "Right Pan":
          this.twentyCellsRightPanning(dotPad);
          break;
        default:
          break;
      }
    },
    onMultipleConnection(dotPad, key) {
      switch (key.name) {
        // 20셀 왼쪽 패닝
        case "Left Pan":
          this.twentyCellsLeftPanning(dotPad);
          break;
        // 20셀 오른쪽 패닝
        case "Right Pan":
          this.twentyCellsRightPanning(dotPad);
          break;
        default:
          break;
      }
    },
    twentyCellsLeftPanning(dotPad) {
      this.$emit('twentyCellsLeftPanning', dotPad);
    },
    twentyCellsRightPanning(dotPad) {
      this.$emit('twentyCellsRightPanning', dotPad);
    },
  }
}
</script>

<style scoped>

</style>
